#rueckblick {
    width: 100%;
    display: block;
    max-width: @outerGridWidth;
    margin: 0px auto 4em auto;
    position: relative;
    .rueckblick-slider {
        .rueckblick-element {
            width: 33%;
            display: none;
            vertical-align: top;
            padding: 0px 20px;
            margin-right: -4px;
            position: relative;
            @media @ipad{
                width: 100%;
                margin-right: 0px;
                padding: 0px;
            }
            .rueckblick-image {
                height: 280px;
                overflow: hidden;
                display: block;
                position: relative;
                margin-bottom: 20px;
                p {
                    position: absolute;
                    z-index: 2;
                    bottom: 0px;
                    left: 10px;
                    font-size: 3em;
                    line-height: 1.1em;
                    color: @white;
                    display: none;
                    height: auto;
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .desc {
                display: none;
            }
            &:nth-child(1) {
                display: inline-block;
                // margin-left: 33%;
                width: 34%;
                @media @ipad{
                    margin-left: 0%;
                    margin-right: 0px;
                    width: 100%;
                }
                .rueckblick-image p {
                    display: block;
                    @media @ipad {
                        left: 20px;
                    }
                }
                .desc {
                    display: block;
                    @media @ipad {
                        padding: 0px 20px;
                    }
                }
            }
            &:nth-child(2) {
                display: inline-block;
                padding: 0px 0px 0px 20px;
                @media @ipad{
                    display: none;
                }
            }
            &:nth-child(3) {
                display: inline-block;
                padding: 0px 0px 0px 20px;
                @media @ipad{
                    display: none;
                }
            }
        }
        &.activated {
            .rueckblick-element {
                display: none;
                .rueckblick-image p {
                    display: none;
                }
                .desc {
                    display: none;
                }
                &.fst {
                    display: inline-block;
                    margin-left: 0%;
                    padding: 0px 20px 0px 0px;
                    width: 33%;
                    @media @ipad {
                        display: none;
                    }
                }
                &.snd{
                    width: 34%;
                    display: inline-block;
                    padding: 0px 20px 0px 20px;
                    .rueckblick-image p {
                        display: block;
                    }
                    .desc {
                        display: block;
                        @media @ipad {
                            padding: 0px 20px;
                        }
                    }
                    @media @ipad {
                        width: 100%;
                        display: block;
                        padding: 0px;
                        .rueckblick-image p {
                            left: 20px;
                        }
                    }
                }
                &.trd {
                    display: inline-block;
                    padding: 0px 0px 0px 20px;
                    @media @ipad {
                        display: none;
                    }
                }
            }
        }
    }
}
.contr {
    width: 45px;
    height: 280px;
    display: block;
    position: absolute;
    background: black;
    top: 0px;
    z-index: 2;
    img {
        position: absolute;
        cursor: pointer;
        top: 50%;
        .transform(translateY(-50%));
    }
    &.cleft {
        left: 0px;
        display: none;
        background: rgba(0,0,0,.7);
        // background: -moz-linear-gradient(left, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 100%);
        // background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,.7)), color-stop(100%, rgba(0,0,0,0)));
        // background: -webkit-linear-gradient(left, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 100%);
        // background: -o-linear-gradient(left, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 100%);
        // background: -ms-linear-gradient(left, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 100%);
        background: linear-gradient(to right, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
    }
    &.cright {
        right: 0px;
        background: rgba(0,0,0,.7);
        // background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,.7) 100%);
        // background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,.7)));
        // background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,.7) 100%);
        // background: -o-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,.7) 100%);
        // background: -ms-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,.7) 100%);
        background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,.7) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
        img {
            right: 0;
        }
    }
}
    /*.rueckblick-element {
        width: 31%;
        &+.rueckblick-element {
            // margin: 0 0 0 2%;
        }

            img {
                position: relative;
                z-index: 1;
                width: auto;
                height: 350px;
            }

        }

    }*/
