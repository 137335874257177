.columns {
    clear: both;
    display: block;
    width: 100%;
    > div {
        display: block;
        width: 100%;
        @media screen and (min-width: @gridWidth) {
            // display: inline-block;
            vertical-align: top;
        }
    }
    .left, .right {
        display: inline-block;
        vertical-align: top;
    }
    .left {
        margin-right: 1em;
    }
    .onethird {
        width: 31.5%;
        @media @ipad {
            width: 100%;
        }
    }
    .twothirds {
        width: 64%;
        @media @ipad {
            width: 100%;
        }
    }

}
