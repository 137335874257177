// IMPORT WEBFONT if you need to
@import (inline) '../css/fontawesome-all.css';
// @import '../css/jquery.bxslider.css';
@import 'modules/_animations.less';
@import 'modules/_base.less';
@import 'modules/_columns.less';
@import 'modules/_mediaQuery.less';
@import 'modules/_reset.less';
@import 'modules/_resetcsc.less';
@import 'modules/_row.less';
@import 'modules/_rueckblick.less';
 
/******
 * BASE ELEMENTS & UTILITIES *
 ******/
* {
    .box-sizing(border-box);
}
html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 1.4em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

strong {
    font-weight: 500;
}

h1,h2,h3 {
    // font-weight: 500;
    line-height: 1.4em;
}

h1, h2 {
    font-size: 2em;
    position: relative;
    left: -18px;
    padding: 0px 0 11px 18px;
    // margin-bottom: 1.5em;
    background: url("../fileadmin/Images/headline.png") bottom left no-repeat;
    background-size: 25px auto;
    margin: 0 0 1em;
    @media @ipad {
        font-size: 1.4em;
    }
}
h3 {
    font-size: 1em;
    font-weight: 500;
}

a {
    color: @green;
    text-decoration: none;
}

.wrap {
	max-width: @gridWidth;
	margin: 0 auto;
	width: 100%;
    padding: 0px 25px;
}

header {
    position: relative;
    width: 100%;
    height: 493px;
    max-width: @outerGridWidth;
    margin: 0 auto;
    overflow: hidden;
    @media @big {
        height: 350px;
        margin: 0px auto;
    }
    .contact {
        position: fixed;
        z-index: 3;
        width: 100%;
        padding: 3px 0 3px;
        text-align: right;
        font-size: 0.9em;
        background: @white;
        height: 27px;
        top: 0px;
        left: 0px;
        font-weight: 400;
        &.wrap {
            height: auto;
        }
        li {
            display: inline-block;
            vertical-align: top;
            &+li {
                margin: 0 0 0 1em;
            }
            @media @mediumpad {
                display: none;
            }
        }
    }
    .background {
        position: absolute;
        z-index: 1;
        top: 27px;
        width: 100%;
        text-align: center;
        img {
            max-width: @outerGridWidth;
            height: auto;
            @media @ipad {
                height: 350px;
                width: auto;
            }
        }
    }
    .wrap {
        position: relative;
        z-index: 2;
        height: 100%;
    }
    .outer_wrap {
        position: relative;
        width: 100%;
        max-width: @outerGridWidth;
        margin: 0px auto;
    }
    nav {
        position: fixed;
        z-index: 3;
        top: 27px;
        left: 0px;
        width: 100%;
        height: 50px;
        // margin: 1em 0;
        text-align: right;
        overflow: hidden;
        font-weight: 400;
        .outer_wrap {
            height: 50px;
            .img {
                position: absolute;
                z-index: 1;
                height: auto;
                overflow: hidden;
                width: 100%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .transition(all 1s);
        }
        .wrap {
            z-index: 2;
            padding: 15px 25px 0 25px;
        }
        li {
            display: inline-block;
            vertical-align: top;
            &+li {
                margin-left: 1em;
            }
            a {
                color: @white;
            }
        }
    }
    .logo {
        display: block;
        position: absolute;
        z-index: 2;
        top: 100px;
        left: 100px;
        right: 100px;
        bottom: 100px;
        @media @big {
            top: 80px;
            left: 50px;
            right: 50px;
            bottom: 50px;
        }
        border: @white 2px solid;
        background: rgba(255,255,255,0.7);
        .wrapInner {
            position: relative;
            width: 100%;
            height: 100%;
            a {
                height: 100%;
                width: auto;
                display: block;
                position: relative;
                margin: 0px auto;
                padding: 50px;
                img {
                    height: 100%;
                    width: auto;
                    display: block;
                    margin: 0px auto;
                    @media @ipad {
                        height: auto;
                        width: 100%;
                        max-width: 350px;
                    }
                }
            }
        }
    }
    #smalllogo {
        position: fixed;
        display: block;
        opacity: 0;
        z-index: 5;
        width: 250px;
        height: auto;
        top: 10px;
        padding: 15px 15px 15px 15px;
        background: white;
        margin-left: 25px;
        .transition(all 1s);
        img {
            width: 100%;
            height: auto;
        }
    }
    &.scrolled {
        .contact {

            background: @green;
            a {
                color: @white;
            }
        }
        nav .outer_wrap {
            background: @white;
            a {
                color: @green;
            }
        }
        #smalllogo {
            opacity: 1;
        }
    }
    .menu_icon {
        display: none;
    }
    @media @ipad {
        height: 27px;
        .contact {
            background: @green;
            a {
                color: @white;
            }
        }
        nav {
            height: auto;
            min-height: 50px;
            overflow: visible;
        }
        nav .outer_wrap {
            background: @white;
            a {
                color: @green;
            }
            display: block;
            height: auto;
            max-height: 0px;
            z-index: 3;
            overflow: scroll;
            height: 100%;
            .transition(all 1s);
            li {
                display: block;
            }
            &.open {
                padding: 75px 25px 25px 25px;
                max-height: 100%;
                a {
                    font-size: 1.6em;
                    line-height: 1.2em;
                }
            }
        }
        .menu_icon {
            display: inline-block;
            text-align: right;
            margin: 15px 25px 0px 0px;
            cursor: pointer;
            span {
                width: 25px;
                height: 3px;
                display: block;
                background: @green;
                margin-top: 3px;
                .transition(all 1s);
            }
            &.x {
                span:nth-child(1) {
                    .transform(rotate(45deg));
                }
                span:nth-child(2) {
                    .transform(rotate(-45deg));
                    margin-top: -3px;
                }
                span:nth-child(3) {
                    display: none;
                }
            }
        }
        #smalllogo {
            opacity: 1;
        }
        .background {
            display: none;
        }
        .logo {
            display: none;
        }
    }
}

section {
    .wrap {
        margin-bottom: 6em;
    }
    .nowrap {
        display: flex;
        @media @ipad {
            flex-wrap: wrap;
        }
    }
    .frame-type-header {
        .wrap {
            margin-bottom: 0;
            padding-top: 6em;
            // border: none;
        }
    }
    &+section {
        .wrap {
            margin-bottom: 6em;

        }
        .frame-type-header {
            .wrap {
                margin-bottom: 0;
                border-top: solid 1px @gray;
                // border: none;
            }
        }
    }
    // div.csc-default {
    //     &+div.csc-default {
    //         // margin: 2em 0 0;
    //     }
    // }
    .content {
        max-width: @outerGridWidth;
        margin: 0 auto;
    }
    .threecolumns {
        display: inline-block;
        width: 31%;
        vertical-align: top;
        &+.threecolumns {
            padding: 0 0 0 2%;
            @media @ipad {
                padding: 0 0 0 0;
            }
        }
        @media @ipad {
            width: 100%;
        }
    }
    .onethird, .twothird {
        display: inline-block;
        // vertical-align: top;
        overflow: hidden;
    }
    .onethird {
        width: 33%;
        @media @ipad {
            width: 100%;
        }
        // &+.onethird {
        //     // margin: 0 0 0 2%;
        // }
        @media @ipad {
            width: 100%;
        }
    }
    .twothird {
        width: 67%;
        @media @ipad {
            width: 100%;
        }
        // &.right {
        //     // margin: 0 0 0 3%;
        // }
        // &.left {
        //     // margin: 0 3% 0 0;
        // }
    }
    .mid {
        width: 34%;
        .produkt.right {
            padding: 10px 20px 20px;
            .description.right {
                right: 0;
            }
            @media @ipad {
                padding: 10px 0px 20px 0px;
            }
        }
        @media @ipad {
            width: 100%;
        }
    }
    .produkt {
        position: relative;
        // max-height: 400px;
        &.right {
            padding: 10px 20px 20px 0;
            @media @ipad {
                padding: 10px 0px 20px 0;
            }
        }
        &.left {
            padding: 10px 0 20px 20px;
            @media @ipad {
                padding: 10px 0px 20px 0;
            }
        }
        &.produkt--pointer {
            &:hover {
                cursor: pointer;
            }
        }
        .produkt-image {
            position: relative;
            width: 100%;
            height: 400px;
            max-height: 400px;
            z-index: 1;
            overflow: hidden;
            background-size: cover;
            background-position: center;
            // margin: 0 0 -10px;
            // img {
            //     width: auto;
            //     height: 100%;
            //     margin: 0 0 -5px;
            // }
        }
        .description {
            position: absolute;
            top: 0;
            // right: -1%;
            bottom: 0;
            text-align: right;
            z-index: 2;
            width: 100%;
            overflow: hidden;
            //height: 100%;
            //max-height: 110px;
            // height: 101%;
            color: @white !important;
            .triangle, .desc {
                .transition(all 0.5s ease);
            }
            .triangle {
                display: inline-block;
                position: absolute;
                right: 0;
                bottom: 20px;
                width: 0;
                height: 0;
                border-style: solid;
            }
            .arrow {
                position: absolute;
                z-index: 2;
                bottom: 32px;
                width: 50px;
                height: 50px;
            }
            .desc {
                position: absolute;
                right: 0;
                bottom: 20px;
                display: inline-block;
                vertical-align: top;
                width: 0;
                height: 100%;
                max-height: 100px;
                // overflow: hidden;
                background-color: @green;
                div {
                    position: absolute;
                    z-index: 3;
                    bottom: 20px;
                    width: 180px;
                    opacity: 0;
                }
                .name-of-person {
                    font-size: 250% !important;
                    font-weight: 500;
                }
                .detail {
                    font-size: 130% !important;
                    font-weight: 500;
                }
            }
            &.right {
                .triangle {
                    right: 20px;
                    border-width: 0 0 100px 100px;
                    border-color: transparent transparent @green transparent;
                    @media @ipad {
                        right: 0px;
                    }
                }
                .arrow {
                    right: 30px;
                    >i {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        .transform(rotate(30deg));
                        font-size: 2.2em;
                    }
                    // background: url('../Images/arrowProductNoGreenLeft.png') bottom right no-repeat;
                    @media @ipad { 
                        right: 10px;
                    }
                }
                .desc {
                    right: 20px;
                    bottom: 20px;
                    div {
                        right: 20px;
                    }
                    @media @ipad {
                        right: 0px;
                    }
                }
                &.act {
                    .triangle {
                        right: 150px;
                        bottom: 10px;
                        border-width: 0 0 500px 120px;
                    }
                    .desc {
                        right: 10px;
                        bottom: 10px;
                        width: 150px;
                        max-height: 500px;
                        margin: 0 0 0 -5px;
                        @media @ipad {
                            right: 0px;
                        }
                        &>div {
                            animation-name: wait_opa;
                            animation-duration: .8s;
                            animation-iteration-count: 1;
                            opacity: 1;
                        }
                    }
                }
            }
            &.left {
                left: 0px;
                .triangle {
                    left: 20px;
                    border-width: 100px 0 0 100px;
                    border-color: transparent transparent transparent @green;
                    @media @ipad {
                        left: 0px;
                    }
                }
                .arrow {
                    left: 30px;
                    >i {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        .transform(rotate(-30deg));
                        font-size: 2.2em;
                    }
                    // background: url('../typo3conf/ext/mm_onepage/Resources/Public/dist/img/arrowProductNoGreenRight.png') bottom left no-repeat;
                    @media @ipad {
                        left: 10px;
                    }
                }
                .desc {
                    left: 20px;
                    bottom: 20px;
                    div {
                        left: 20px;
                    }
                    @media @ipad {
                        left: 0px;
                    }
                }
                &.act {
                    .triangle {
                        left: 150px;
                        bottom: 10px;
                        border-width: 500px 0 0 120px;
                    }
                    .desc {
                        left: 10px;
                        bottom: 10px;
                        width: 150px;
                        max-height: 500px;
                        // margin: 0 0 0 -5px;
                        color: @white;
                        background-color: @green;
                        @media @ipad {
                            left: 0px;
                        }
                        div {
                            // position: absolute;
                            // right: 10px;
                            // bottom: 10px;
                            // width: 180px;
                            animation-name: wait_opa;
                            animation-duration: .8s;
                            animation-iteration-count: 1;
                            opacity: 1;
                        }
                    }
                }
            }
            &.act {
                max-height: 100%;
                .arrow {
                    >i {
                        display: none;
                    }
                }
                .desc {
                    bottom: 10px;
                }
            }
        }
    }
    &#section-4 {
        .frame-type-header {
            position: relative;
            display: block;
            z-index: 2;
            p {
                max-width: 33%;
            }
        }
        .slider-box {
            position: relative;
            display: block;
            z-index: 1;
            top: -200px;
        }
    }
    .tx-powermail {
        .powermail_label {
            display: none;
        }
        .left, .right {
            display: inline-block;
            vertical-align: top;
            width: 49.5%;
            margin: 0;
            @media @ipad {
                width: 100%;
                margin-top: 15px;
            }
        }
        input, textarea {
            font-family: 'Roboto', sans-serif;
            font-weight: 100;
            font-size: 16px;
            line-height: 1.33em;
            border: solid 1px @black;
        }
        input {
            display: inline-block;
            width: 90%;
            height: 2em;
            padding: 0 5px;
            margin: 0 0 0.8em;
            @media @ipad {
                width: 100%;
            }
        }
        textarea {
            display: inline-block;
            width: 100%;
            height: 7.6em;
            margin: 0 0 0.8em;
            padding: 5px 5px;
        }
        .powermail_fieldwrap_type_submit {
            text-align: right;
            .powermail_submit {
                width: 60%;
                background: @white;
                text-shadow:none !important;
                box-shadow:none !important;
                border: solid 1px @black;
            }
        }
        .powermail_legend,
        .container-fluid h3 {
            display: none;
        }

        // KOMMASEPARIERTE AUFLISTUNG FUNKTIONIERT NICHT!
        ::-webkit-input-placeholder {
            color: @black;
        }
        :-moz-placeholder {
            color: @black;
        }
        ::-moz-placeholder {
            color: @black;
        }
        :-ms-input-placeholder{
            color: @black;
        }
    }
}

footer {
    width: 100%;
    max-width: @outerGridWidth;
    margin:  0px auto;
    padding: 4em 0 0;
    border-top: solid 12px @green;

    .left, .right {
        display: inline-block;
        vertical-align: top;
    }
    .left {
        width: 60%;
        .leftLeft, .leftRight {
            display: inline-block;
            width: 49.5%;
            vertical-align: top;
        }
        @media @ipad {
            width: 100%;
            .leftLeft, .leftRight {
                width: 100%;
            }
        }
    }
    .right {
        width: 39.5%;
        @media @ipad {
            width: 100%;
        }
        .socialMedia {
            li {
                display: inline-block;
                vertical-align: top;
                &+li {
                    margin: 0 0 0 0.5em;
                }
                img {
                    width: 45px;
                    height: 45px;
                }
            }
        }
    }
    .tel, .socialMedia {
        margin: 2em 0 0;
    }
    .bottom {
        position: relative;
        display: flex;
        align-items: center;
        top: 2em;
        margin-bottom: 50px;
        img {
            margin-left: 10px;
            height: 9px;
            width: auto;
        }
    }
}

#startimage {
    @media (max-width: 360px) {
        display: none;
    }
}
img {
    display: block;
    width: 100%;
    height: auto;
}